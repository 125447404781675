import React, { useEffect, useState } from "react";
import "./ComingSoon.css";
import { NavLink } from "react-router-dom";
import { FormControl, TextField } from "@mui/material";
import { PlayVideoIcon } from "../../SvgIcons/Icons";

const ComingSoon = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const 
  calculateTimeLeft = () => {
    const targetDate = new Date("2024-12-01T23:59:59"); // Set your target date here
    const now = new Date();
    const difference = targetDate - now;

    if (difference > 0) {
      setTimeLeft({
        days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
          2,
          "0"
        ),
        hours: String(
          Math.floor((difference / (1000 * 60 * 60)) % 24)
        ).padStart(2, "0"),
        minutes: String(Math.floor((difference / (1000 * 60)) % 60)).padStart(
          2,
          "0"
        ),
        seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
      });
    } else {
      setTimeLeft({ days: "00", hours: "00", minutes: "00", seconds: "00" });
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className="coming_soonMain position-relative z-1">
      <div className="container pt-4 pb-5">
        <div>
          <NavLink to="/">
            <img
              className="comingSoonLogo"
              src={require("../Images/Web_logo.png")}
              alt="AssignmentSold Logo"
              loading="lazy"
            />
          </NavLink>
        </div>

        <div className="row mt-3 align-items-center">
          <div className="col-md-7">
            {/* timer */}

            <div className="d-flex mt-4">
              <div className="coming_soonTimer">
                <h6>{timeLeft.days}</h6>
                <p>Days</p>
              </div>
              <div className="coming_soonTimer">
                <h6>{timeLeft.hours}</h6>
                <p>Hours</p>
              </div>
              <div className="coming_soonTimer">
                <h6>{timeLeft.minutes}</h6>
                <p>Minutes</p>
              </div>
              <div className="coming_soonTimer border-end-0">
                <h6>{timeLeft.seconds}</h6>
                <p>Seconds</p>
              </div>
            </div>

            <div>
              <h2 className="mt-4">Launching Soon!</h2>
              <h1 className="mt-3">
                Building Canada's largest marketplace for off‑market listings.
              </h1>
              <h3 className="mt-4">
                AssignmentSold is powerful digital platform created for the
                forward-thinking real estate agents, brokerages, and investors
                providing access to Off-Market and Assignment Properties.
              </h3>
              <h3 className="mt-3">
                Bundled with the Latest Technology Innovations and Premium
                Marketing, AssignmentSold is going to Game Changer. Sign up Now
                to Get first Access.
              </h3>
            </div>

            <div className="mt-4 w-100 d-flex gap-2">
              <FormControl
                style={{ maxWidth: "380px", width: "calc(100% - 115px)" }}
              >
                <TextField
                  sx={{
                    borderRadius: "4px",
                  }}
                  size="small"
                  className="w-100 bg-light"
                  // value={formik.values.brokerageName}
                  // onChange={formik.handleChange}
                  name="brokerageName"
                  id="brokerageNameF"
                  label={<span className="font">Enter your email</span>}
                  variant="outlined"
                />
                {/* {isSubmitted ? (
                    <span style={{ color: "red" }}>
                      {formik.errors.brokerageName}
                    </span>
                  ) : null} */}
              </FormControl>
              <button className="propertyDetails_moreInfo py-2 px-3 PropDetails_gradientBtn">
                Notify Me
              </button>
            </div>

            <div className="d-flex align-items-center comingSoon_subscriber mt-4 pb-4">
              <img
                src="/images/MortgageAdvisor/pic1.jpeg"
                loading="lazy"
                alt="subscriber-profile"
              />
              <img
                src="/images/MortgageAdvisor/pic2.jpeg"
                loading="lazy"
                alt="subscriber-profile"
              />
              <img
                src="/images/MortgageAdvisor/pic3.jpeg"
                loading="lazy"
                alt="subscriber-profile"
              />
              <img
                src="/images/MortgageAdvisor/pic1.jpeg"
                loading="lazy"
                alt="subscriber-profile"
              />
              <img
                src="/images/MortgageAdvisor/pic2.jpeg"
                loading="lazy"
                alt="subscriber-profile"
              />
              <div className="subscriberCount">
                <span>+50</span>
              </div>
              <div>
                <p className="ps-2 m-0">Subscribed</p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="comingSoon_thumbnail">
              <img
                src="https://config.assignmentsold.ca/Attachments/Assignment/00168611-Screenshot%202024-10-22%20174649.png"
                alt="video-thumbnail"
                className="w-100"
                loading="lazy"
              />
              <button onClick={handleOpenModal}>
                <PlayVideoIcon />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="position-absolute bottom-0 w-100" style={{ zIndex: -1 }}>
        <img
          src="/images/ComingSoonFooter.png"
          alt="comingSoon-footer"
          loading="lazy"
          width={"100%"}
        />
      </div>

      {/* Video Modal Work */}
      {isModalOpen && (
        <div className="video-modal">
          <div
            className="video-modal-overlay"
            onClick={handleCloseModal}
          ></div>
          <div className="video-modal-content">
            <video
              className="video-player"
              controls
              controlsList="nodownload"
              playsInline
              autoPlay
              src="https://vapi.homes.com/video/play/4i9q3d?source=42&amp;size=1080"
              poster="http://cf.cdn.vid.ly/4i9q3d/poster.jpg"
            ></video>
            <button
              className="comingSoon_closebutton"
              onClick={handleCloseModal}
            >
              ✖
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComingSoon;
